<template>
    <div id="testimonial">
        <vueper-slides 
            autoplay
            fade
            :bullets="false"
            :arrows="false"
            class="no-shadow"
            :touchable="false"
            :pauseOnHover="false"
            :duration="5000"
            >
            <vueper-slide
                v-for="(slide, i) in slides"
                :key="i"
                class="testimonialSlide">
                <template v-slot:content>
                    <div class="slideContent">
                        <h2>
                            "{{slide.content}}"
                        </h2>
                    </div>
                </template>
            </vueper-slide>
        </vueper-slides>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'

export default {
    name: "Testimonial",
    components: { VueperSlides, VueperSlide },
    data() {
    return {
        slides: [
            {
                content: "She is responsive, attentive, and a smart negotiator.",
                author: "Dione"
            },
            {
                content: "Hannah is extremely professional, warm and very attentive.",
                author: "Client"
            },
            {
                content: "She is so good at her job it feels like your business is being handled by an old family friend.",
                author: "Client"
            },
            {
                content: "She has excellent follow-through, attention to detail, stays true to her word, and goes above and beyond to ensure you are pleased.",
                author: "Kim"
            },
            {
                content: "Hannah has gone above and beyond in ensuring my needs were met to the utmost.",
                author: "Natalie"
            },
        ],
        }
    }
}
</script>
<style lang="scss">
#testimonial .testimonialSlide{
    top: unset !important;
    left: unset !important;
    transform: unset !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#testimonial .testimonialSlide .slideContent{
    max-width: 700px;
    margin: 0 17.5%;
}

#testimonial .testimonialSlide .slideContent h2{
    font-style: italic;
    font-weight: 400;
    font-size: 1.75rem;
}

#testimonial .testimonialSlide .slideContent .author{
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 3px;
    font-style: normal;
}

#testimonial .vueperslides__parallax-wrapper{
    padding-bottom: 0 !important;
}
#testimonial .vueperslides__inner>div{
    height: 250px;
}

@media (max-width: 780px) { 
    #testimonial .testimonialSlide .slideContent h2{
        top: unset;
        left: unset;
        transform: unset;
        margin: 2rem 0;
    }
}
@media (max-width: 576px) {
    #testimonial{
        padding: 2rem 0;
    }
    #testimonial .vueperslides__inner>div{
        height: 210px;
    }
    #testimonial .testimonialSlide .slideContent h2{
        font-size: 1.5rem;
    }
}
</style>