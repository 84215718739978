<template>
    <div id="home">
        <BackgroundSlideshow />
        <div id="intro">
            <Testimonial 
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000"
                v-bind:data-aos-delay="100"
            />
            <HomepageSlideshow id="featured-listings"/>
        </div>
        <div id="agent">
            <div class="profilePhoto"
                data-aos="fade-right"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000">
                <img src="/images/site_owner.jpg" alt="Hannah DelPonte Vollera"/>
            </div>
            <div class="container" 
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000"
                v-bind:data-aos-delay="300"
                >
                <div class="content">
                    <h1>{{introTitle}}</h1>
                    <p v-html="homePageContent"></p>
                    <router-link to="/about" class="more">
                        MORE
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Testimonial from '../components/Testimonial'
import HomepageSlideshow from '../components/HomepageSlideshow.vue'
import BackgroundSlideshow from '../components/BackgroundSlideshow.vue'

export default {
    name: "Home",
    components: {
    HomepageSlideshow,
    Testimonial,
    BackgroundSlideshow,
},
    metaInfo: {
        title: 'Hannah DelPonte Vollera | Luxury Real Estate',
        meta: [{
            name: 'description', 
            content: "Hannah DelPonte Vollera | Luxury Real Estate"
        }]
    },
    data(){
        return{
            introTitle: "Detailed, Above and Beyond Commitment",
            homePageContent: "Hannah DelPonte Vollera brings passion, joy, enthusiasm and experience into the world of luxury -real estate. Through her experience in the industry she has developed many valuable relationships with many buyers and sellers and investors. Hannah specializes in coastal properties and has a niche in Los Angeles westside neighborhoods. Hannah started her career in luxury high-rise condominium communities of Azzurra, Cove and Regatta in Marina del Rey and loves working in the coastal community.",
        }
    },
}   
</script>

<style lang="scss" scoped>
#agent{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 75px 15% 75px;
    .container{
        flex: 1;
        .content{
            text-align: left;
            margin: 1rem 0;
            max-width: 600px;
            h1{
                letter-spacing: 1px;
                font-size: 2rem;
                font-weight: 500;
            }
            p{
                margin: 3rem 0;
            }
            a{
                text-transform: uppercase;
                letter-spacing: 2px;
                text-decoration: none;
                font-weight: 600;
                color: $primaryColor;
                &:hover{
                    opacity: 0.75;
                }
            }
        }
    }
    .profilePhoto{
        width: 40%;
        padding-right: 3rem;
        img{
            object-fit: cover;
            width: 100%;
            display: block;
        }
    }
}
#intro{
    width: 100%;
    height: 100%;
}

@media (max-width: 780px) {
    #agent{
        .profilePhoto{
            padding-right: 2rem;
            width: 35%;
        }
    }
}
@media (max-width: $mobile) { 
    #agent{
        margin-left: 0;
        margin-right: 0;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        margin-top: 0rem;
        .container{
            flex: 1;
            .content{
                text-align: center;
                margin: 2rem;
                h1{
                    text-transform: unset;
                    letter-spacing: 2px;
                }
                p{
                    text-align: left;
                    margin: 1rem 0;
                }
                a{
                    margin-top: 2rem;
                    text-align: center;
                    text-transform: uppercase;
                    letter-spacing: 6px;
                }
            }
        }
        .profilePhoto{
            margin-top: 0;
            width: 100%;
            text-align: center;
            padding-right: 0;
            margin: 2rem;
            img{
                display: block;
                object-fit: cover;
                object-position: 0 0;
                height: 300px;
            }
        }
    }
}

</style>